// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, forwardRef } from 'react';

import styles from './index.module.css';

type CustomInputProps = {
  type: string;
  name: string;
  id: string;
  initialValue: string;
  placeholder: string;
  dataRecurly: string;
  inputEventHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: string[];
  checkFormValidity: () => void;
  handleInputError: (name: string, error: boolean) => void;
  errorMessage?: string;
};

export const CustomInput = forwardRef<CustomInputProps>(function CustomInput(
  {
    type,
    name,
    id,
    initialValue,
    placeholder,
    dataRecurly,
    inputEventHandler,
    errors,
    checkFormValidity,
    handleInputError,
    errorMessage = null,
  },
  inputRef,
) {
  const [value, setValue] = useState(initialValue);
  const [placeholderClassList, setPlaceholderClassList] = useState(styles.customInputPlaceholder);

  const errorsObject = {};
  if (errors && errors.includes(name)) {
    errorsObject.name = errorMessage ? errorMessage : `${placeholder} required`;
  }

  function handleInput() {
    if (checkFormValidity) {
      checkFormValidity();
    }
    setValue(inputRef.current.value);
    setPlaceholderClassList(styles.customInputPlaceholderIsActive);
  }

  function handleFocus() {
    setPlaceholderClassList(styles.customInputPlaceholderIsActive);
  }

  function handleBlur() {
    inputRef.current.value == ''
      ? setPlaceholderClassList(styles.customInputPlaceholder)
      : setPlaceholderClassList(styles.customInputPlaceholderIsActive);
    if (inputRef.current.value == '' && handleInputError) {
      handleInputError(name, true);
    } else if (inputRef.current.value != '' && handleInputError) {
      handleInputError(name, false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={!!errorsObject.name == true ? styles.customInputContainerError : styles.customInputContainer}>
        <p className={value && value != '' ? styles.customInputPlaceholderIsActive : placeholderClassList}>
          {placeholder}
        </p>
        <input
          className={styles.customInput}
          data-recurly={dataRecurly}
          id={id}
          name={name}
          onBlur={handleBlur}
          onChange={inputEventHandler}
          onFocus={handleFocus}
          onInput={handleInput}
          ref={inputRef}
          type={type}
          value={value}
        />
      </div>
      {!!errorsObject.name == true ? <p className={styles.errorText}>{errorsObject.name}</p> : ''}
    </div>
  );
});
