import styled from '@emotion/styled';

type MainProps = {
  bg?: string;
};

export const mainBackgroundString = `
	url(/images/section_background/6_20.svg),
	url(/images/section_background/5_84.svg),
	url(/images/section_background/4_30.svg),
	url(/images/section_background/3_84.svg),
	url(/images/section_background/2_94.svg),
	url(/images/section_background/1_100.svg)
`;

export const mainBackgroundSize = 'contain';
export const mainBackgroundAttachment = 'fixed';

const Main = styled.main<MainProps>((props) => ({
  flexGrow: 1,
  background: props.bg ? props.bg : mainBackgroundString,
  backgroundSize: props.bg ? undefined : mainBackgroundSize,
  backgroundAttachment: mainBackgroundAttachment,
  backgroundColor: props.bg ? props.bg : undefined,
  position: 'relative',
  paddingBottom: '40px',
}));

export default Main;
