/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { detected } from 'adblockdetect';
import cookie from 'js-cookie';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { toast } from 'react-toastify';
import { useMedia, useSessionStorage } from 'react-use';

import BadgeToast from '@/components/badges/BadgeToast';
import { Container, Modal, useUI, useUIDispatch } from '@/components/design-system';
import { LiveEpisodeToast } from '@/components/episodes/LiveEpisodeToast';
import { Drawer } from '@/components/ui';
import { useAuth0 } from '@/context/auth';
import { SITE_URL } from '@/lib/constants';
import { useEpisodesLive } from '@/request/episode';
import { breakpoints } from '@/utils/styles';

import Link from '../shared/Link';

import AllPageBannerV5 from './AllPageBannerV5';
import { DesktopMenu } from './DesktopMenu';
import { Footer } from './Footer';
import { MobileMenu } from './MobileMenu';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { getUtmCookieData } from '@/lib/utm_cookie';
import ElectionFooter from '../homepage/electionday/ElectionFooter';
import { useLightModeToggle } from '@/context/LightModeToggleContext';
import ElectionBanners from '@/components/homepage/electionday/ElectionBanners';

interface ILayoutProps {
  children?: React.ReactNode;
}

const routesToHideNavAndFooter = ['/account'];

export const Layout: React.FC<ILayoutProps> = ({ children }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const { subscriber, subscription, loading } = useAuth0();
  const router = useRouter();

  const { data: liveEpisodeData } = useEpisodesLive();

  const isDesktopViewport = useMedia(`(min-width: ${breakpoints.desktop}px)`, false);
  const [value, setValue] = useSessionStorage<string>('engaged-live-episode-toast', '');
  const firstLiveEpisode = liveEpisodeData?.getLiveEpisodes?.[0];
  const liveEpisodeSlugs = liveEpisodeData?.getLiveEpisodes.map((ep) => ep.slug);
  const pageIsLiveEpiside = liveEpisodeSlugs?.includes(String(router.query.slug));
  const hasSeenLiveToast = value && liveEpisodeSlugs?.includes(value);
  const isSubscribeRoute = router.asPath.startsWith('/subscribe');
  const hideNavAndFooter = routesToHideNavAndFooter.includes(router.asPath);
  const { isLightModeActive } = useLightModeToggle();

  const {
    displayModal,
    modalContent,
    modalOnClose,
    displayDrawer,
    drawerContent,
    drawerPosition,
    drawerTitle,
    drawerBg,
  } = useUI();

  const uiDispatch = useUIDispatch();

  React.useEffect(() => {
    if ((window as any)?.wisepops) {
      (window as any)?.wisepops('pageview');
    }
  }, []);

  // "on arrival" badge awarding
  React.useEffect(() => {
    if (subscriber && subscriber.onArrivalBadge) {
      toast(<BadgeToast badge={subscriber.onArrivalBadge} />);
    }
  }, [subscriber]);

  React.useEffect(() => {
    const isBlockingAds = detected();
    const adBlockCookie = cookie.get('adBlockDetected');
    if (isBlockingAds && !adBlockCookie) {
      cookie.set('adBlockDetected', '1', { expires: 1 });
    }
    if (!isBlockingAds && adBlockCookie) {
      cookie.remove('adBlockDetected');
    }
  }, []);

  // Live Episode Toast
  React.useEffect(() => {
    let liveToast: React.ReactText;

    if (firstLiveEpisode) {
      // Dismiss active toast on subscribe routes
      if (isSubscribeRoute) {
        toast.dismiss(liveToast);
      }

      // Dismiss active toast and set session storage on live episode page
      if (pageIsLiveEpiside && !hasSeenLiveToast) {
        toast.dismiss(liveToast);
        setValue(firstLiveEpisode.slug);
      }

      // Display Toast
      if (!pageIsLiveEpiside && !hasSeenLiveToast && !isSubscribeRoute) {
        liveToast = toast(
          <LiveEpisodeToast
            showName={firstLiveEpisode.show.name}
            slug={firstLiveEpisode.slug}
            title={firstLiveEpisode.title}
          />,
          {
            position: 'bottom-center',
            autoClose: 120000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            onClose: () => {
              setValue(firstLiveEpisode.slug);
            },
            onClick: () => {
              const abTestData = getABTestAnalyticData();
              const utmCookieData = getUtmCookieData();

              setValue(firstLiveEpisode.slug);
              window.__DW_Next_Bridge?.Analytics.logEvent('CTA Clicked', {
                ...abTestData,
                ...utmCookieData,
                type: 'Live Episode Announcement',
                text: `Streaming Live: ${firstLiveEpisode.title} - ${firstLiveEpisode.show.name}`,
                destination: `${SITE_URL}/episode/${firstLiveEpisode.slug}`,
                location: 'Toast: Bottom, Center',
                page_url: window.location.href,
              });
            },
          },
        );
      }
    }
  }, [firstLiveEpisode, hasSeenLiveToast, isSubscribeRoute, pageIsLiveEpiside, setValue]);

  const isHomePageElection = router.pathname === '/' && isLightModeActive;
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <div
        className={`${isHomePageElection ? 'version2-layout-election' : 'version2-layout'}`}
        css={{
          /* DO NOT ADD overflow-x: hidden; It makes position: sticky; break */
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            className={`${isLightModeActive ? 'menu-container-election' : 'menu-container'}`}
            css={(theme) => ({
              zIndex: 100,
              backgroundColor: theme.colors.gray98,
              color: theme.colors.white,
            })}
            data-label='global-header'
          >
            {hideNavAndFooter ? null : (
              <Container
                css={(theme) => ({
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: 'auto',

                  [theme.mq.desktop]: {
                    display: 'grid',
                    gridTemplateColumns: '230px 1fr',
                    justifyContent: 'flex-start',
                    height: 'auto',
                  },
                })}
              >
                <Link className='logo' data-label='logotype' href='/' style={{ padding: '10px 0px' }}>
                  <Image
                    className='daily-wire-logo'
                    height={`${isMobile ? '50' : '90'}`}
                    src={`${isHomePageElection ? '/images/version2/LogoDark.svg' : '/images/version2/Logo.svg'}`}
                    width='230'
                  />
                </Link>
                {isDesktopViewport ? (
                  <DesktopMenu />
                ) : (
                  <div css={{ display: 'flex' }}>
                    <MobileMenu />
                  </div>
                )}
              </Container>
            )}
          </div>
          {!isHomePageElection && (
            <AllPageBannerV5
              altText='Watch Now'
              alwaysTimer={true}
              email={subscriber?.email || null}
              endDate='2024-10-27T23:01:00'
              mobileTitleSuffix='8:30ET'
              removeDate='2024-11-04T01:00:00'
              startDate='2024-10-11T00:00:00'
              subscription={subscription}
              titleSuffix='8:30ET'
            />
          )}
        </div>
        {children}

        {!hideNavAndFooter && (isHomePageElection ? <ElectionFooter /> : <Footer />)}
      </div>
      <Modal
        onRequestClose={() => {
          modalOnClose?.();
          uiDispatch({ type: 'CLOSE_MODAL' });
        }}
        showModal={displayModal}
      >
        {modalContent}
      </Modal>
      <Drawer
        bg={drawerBg}
        onClose={() => uiDispatch({ type: 'CLOSE_DRAWER' })}
        open={displayDrawer}
        position={drawerPosition}
        title={drawerTitle}
      >
        {drawerContent}
      </Drawer>
      {!loading && subscriber && subscriber.email && (
        <Script
          dangerouslySetInnerHTML={{
            __html: `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
        a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
        s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
        profitwell('start', { 'user_email': '${subscriber.email}' });`,
          }}
          data-pw-auth='0c1fe151399416443a686fb86a90a474'
          id='profitwell-js'
        />
      )}
    </>
  );
};

Layout.displayName = 'Layout';
